//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      cmsSettings: 'settings/cms',
    }),
    hasFooterData() {
      return this.footerData && Object.keys(this.footerData).length > 0;
    },
    hasCmsData() {
      return this.cmsSettings && Object.keys(this.cmsSettings).length > 0;
    },
  },
  data() {
    return {
      imageOptions: {
        slidesToShow: 1,
        slidesToScroll: 1,
        asNavFor: this.$refs.testimonialContentSlider,
        swipe: true,
        focusOnSelect: true,
        arrows: false,
        infinite: true,
        fade: true,
      },
      contentOptions: {
        slidesToShow: 1,
        slidesToScroll: 1,
        asNavFor: this.$refs.testimonialImageSlider,
        arrows: false,
        dots: true,
        fade: true,
        swipe: true,
        infinite: true,
        adaptiveHeight: true,
        lazyLoad: 'progressive',
        autoplay: true,
        autoplaySpeed: 6000,
      },
      footerData: undefined,
    };
  },
  methods: {
    beforeChange(pos, next) {
      this.$refs.testimonialContentSlider.goTo(next);
      this.$refs.testimonialImageSlider.goTo(next);
    },
  },
  async mounted() {
    if (!this.hasCmsData) {
      await this.$store.dispatch('settings/fetchCmsSettings');
    }

    const cmsSettings = this.cmsSettings;
    this.footerData = cmsSettings?.footer ?? undefined;
  },
};
