var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.hasFooterData)?_c('section',{staticClass:"section testimonials max-page-width"},[_c('div',{staticClass:"flex-parent"},[_c('div',{staticClass:"sliders-wrapper"},[(
          _vm.footerData.footer_testimonial_list &&
            _vm.footerData.footer_testimonial_list.length > 0
        )?_c('vue-slick-carousel',_vm._b({ref:"testimonialImageSlider",staticClass:"slider-image gallery-top",on:{"beforeChange":_vm.beforeChange}},'vue-slick-carousel',_vm.imageOptions,false),_vm._l((_vm.footerData
            .footer_testimonial_list),function(testimonial,index){return _c('div',{key:'image-' + index,staticClass:"slide slide--image"},[_c('div',{staticClass:"testimonials__image"},[(testimonial.testimonial_image)?_c('media',{attrs:{"id":testimonial.testimonial_image.toString(),"lazy-load":false}}):_vm._e()],1)])}),0):_vm._e(),_vm._v(" "),(
          _vm.footerData.footer_testimonial_list &&
            _vm.footerData.footer_testimonial_list.length > 0
        )?_c('vue-slick-carousel',_vm._b({ref:"testimonialContentSlider",staticClass:"slider-content gallery-thumbs",on:{"beforeChange":_vm.beforeChange}},'vue-slick-carousel',_vm.contentOptions,false),_vm._l((_vm.footerData
            .footer_testimonial_list),function(testimonial,index){return _c('div',{key:'content-' + index,staticClass:"swiper-slide slide slide--sync"},[_c('blockquote',{staticClass:"testimonials__content-quote wysiwyg-editor",domProps:{"textContent":_vm._s(testimonial.testimonial_content)}}),_vm._v(" "),_c('div',{staticClass:"testimonials__content-author wysiwyg-editor",domProps:{"innerHTML":_vm._s(testimonial.testimonial_name_or_position)}})])}),0):_vm._e()],1)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }