//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import HomeSvg from '~/static/svg/home.svg';
import ArrowRight from '~/static/svg/arrow-right.svg';

export default {
  name: 'Breadcrumb',
  components: {
    ArrowRight,
    HomeSvg,
  },
  props: {
    links: {
      type: Array,
      default: [],
    },
  },
};
